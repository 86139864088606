const faqs = [];



const titles = [
    'What is the importance things of APICAL Mock Test',
    'This is the second common question',
    'What about MOC test exam process',
    'I want to know about apical',
    'English is our second language'
]

const body = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam accusantium voluptates unde sit cupiditate! Cum est vel, natus quibusdam itaque veritatis quaerat numquam! Facere facilis aspernatur error doloremque, iste fugiat laborum, ut consectetur, natus dolor vero excepturi cum aliquid nisi nulla deleniti temporibus. Reprehenderit, recusandae magnam! Eos error distinctio ut libero, ratione officiis doloremque magnam, assumenda sit aut eveniet non sint laudantium! Minima repudiandae atque dignissimos quisquam autem, voluptate necessitatibus quaerat eligendi praesentium cupiditate fugit sunt repellendus nihil minus deleniti labore ab dolore molestiae accusamus iste quia fugiat? Esse quas ullam ab quia tempore sint deserunt neque quisquam, suscipit laboriosam praesentium magnam harum nostrum mollitia magni. Ut officia, odio autem aliquid ullam at reiciendis, assumenda corrupti minima, rem pariatur fugiat vel quibusdam veniam eaque sit illum sed accusamus? Ex perspiciatis vero soluta deleniti nobis ducimus saepe exercitationem facere, fuga tempore quod dolore, nemo laudantium sit eos nisi, a doloribus odit.'

for (let i = 0; i < 10; i++) {
  faqs.push({
    id: i,
    title: titles[Math.floor(Math.random() * 5)],
    body: body
  });
}

export default {
    faqs
}