<template>
  <div>
    <h2 class="text-uppercase text-lg mb-6">FAQ</h2>
    <SearchComponent placeholder="Search anything" class="p-3 mb-6" />
    <template v-for="(item, index) in items">
    <OutlineCollapse
      @on-click="onToggle(index)"
      :title="item.title"
      :active="currentIndex == index"
      :key="index"
      bg-color="transparent"
    >
      <p class="text-base line-height-27">
        {{ item.body }}
      </p>
    </OutlineCollapse>
    </template>
  </div>
</template>

<script>
import OutlineCollapse from "@/components/atom/OutlineCollapse";
import SearchComponent from "@/components/atom/SearchBox";
import data from "@/data/faq/data"
export default {
  name: 'FAQ-View',
  components: {
    OutlineCollapse,
    SearchComponent
  },
  data() {
    return {
      currentIndex: 0,
      items: data.faqs
    }
  },
  methods: {
    onToggle(index) {
        this.currentIndex = index
    }
  }
}
</script>